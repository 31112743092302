
<template>
  <div class="page d-flex flex-row flex-column-fluid">
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <div class="card mb-5 mb-xxl-8 custom-card h-100 px-5">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1"
              >Horarios Disponibles
              {{ consultandoDisponibilidadRequest.fecha }}
            </span>

            <span class="text-muted mt-1 fw-bold fs-7"
              >Establecimiento: {{ establecimiento.nombre }}</span
            >
            <span class="card-title align-items-center flex-row">
              <div>
                <a
                  class="btn btn-sm btn-light-warning m-1"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                  v-for="(profesional, index) in profesionales"
                  :key="index"
                  @click="filtrarArrayPorProfesional(profesional)"
                >
                  <span class="svg-icon svg-icon-3">
                    <!--<inline-svg
                      src="media/icons/duotone/Communication/Add-user.svg"
                    />-->
                  </span>
                  {{ profesional.nombre }} {{ profesional.apellido }}
                </a>
              </div>
            </span>
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body py-3">
          <div class="spinner-border" v-show="procesando" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>

          <div
            class="spinner-border"
            v-if="consultandoDisponibilidad"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <!--begin::Table container-->

          <div v-if="!procesando">
            <div v-if="!consultandoDisponibilidad" class="table-responsive">
              <!--begin::Table-->
              <table
                class="
                  table table-row-dashed table-row-gray-300
                  align-middle
                  gs-0
                  gy-4
                "
                v-if="lenHorariosDisponibles > 0"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="fw-bolder text-muted">
                    <!--<th class="w-25px">
                    <div
                      class="
                        form-check
                        form-check-sm
                        form-check-custom
                        form-check-solid
                      "
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                      />
                    </div>
                  </th>-->
                    <th class="min-w-150px">Hora</th>
                    <th class="min-w-140px">Servicio</th>
                    <th class="min-w-120px">Duración</th>
                    <th class="min-w-120px">Valor</th>
                    <th class="min-w-100px text-end">Actions</th>
                  </tr>
                </thead>
                <!--end::Table head-->

                <!--begin::Table body-->
                <tbody>
                  <template
                    v-for="(item, index) in horariosDisponiblesFiltro"
                    :key="index"
                  >
                    <tr>
                      <!-- <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          :value="index"
                        />
                      </div>
                    </td>-->

                      <td>
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-45px me-5">
                            <img src="media/mrl/reloj.png" alt="" />
                          </div>
                          <div class="d-flex justify-content-start flex-column">
                            <a
                              class="
                                text-dark
                                fw-bolder
                                text-hover-primary
                                fs-6
                              "
                              >{{ item.hora }}</a
                            >

                            <span
                              class="text-muted fw-bold text-muted d-block fs-7"
                              >{{ item.profesional.nombre }}
                              {{ item.profesional.apellido }}</span
                            >
                          </div>
                        </div>
                      </td>

                      <td>
                        <b> {{ item.servicio.nombre }}</b>

                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.servicio.descripcion }}</span
                        >
                      </td>

                      <td class="align-items-center">
                        <b v-if="item.servicio.duracionHoras > 0">
                          Horas: {{ item.servicio.duracionHoras }}</b
                        >
                        <b v-if="item.servicio.duracionMinutos > 0">
                          Minutos: {{ item.servicio.duracionMinutos }}</b
                        >
                      </td>

                      <td class="align-items-center">
                        <b v-if="item.servicio.valor > 0">
                          {{ valorServicio(item.servicio.valor) }}</b
                        >
                      </td>

                      <td class="text-end">
                        <div
                          class="card-toolbar"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Click to add a user"
                        >
                          <a
                            @click="seleccionarReserva(item)"
                            class="btn btn-sm btn-light-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_invite_friends"
                          >
                            <span class="svg-icon svg-icon-3">
                              <!--<inline-svg
                                src="media/icons/duotone/Communication/Add-user.svg"
                              />-->
                            </span>
                            <img src="media/icons/duotone/Code/Plus.png">
                            Seleccionar
                          </a>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Table container-->
        </div>
        <!--begin::Body-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Reserva } from "@/models/reserva.model";
import { ServicioProfesionalResponse } from "@/models/servicio-profesional-response.model";
import { Usuario } from "@/models/usuario.model";
import { Utils } from "@/utils/utils";
import { computed, defineComponent, onMounted, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import * as Bowser from "bowser";

export default defineComponent({
  name: "Disponibilidad",
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { id } = toRefs(props);

    const store = useStore();

    const router = useRouter();

    const utils: Utils = new Utils();

    const horariosDisponiblesOriginal = ref([]);
    const horariosDisponiblesFiltro = ref([]);
    const profesionales = ref(<Usuario[]>[]);
    const profesionalesMap: Map<string, Usuario> = new Map<string, Usuario>();
    const procesando = ref(false);

    const establecimiento = computed(
      () => store.getters["establecimiento/getterEstablecimiento"]
    );

    const consultandoDisponibilidadRequest = computed(
      () => store.getters["disponibilidad/getterDisponibilidadRequest"]
    );

    const consultandoDisponibilidad = computed(
      () => store.getters["disponibilidad/getterConsultandoDisponibilidad"]
    );

    const disponibilidad = computed(
      () => store.getters["disponibilidad/getterDisponibilidad"]
    );

    const progresoReserva = computed(
      () => store.getters["reserva/getterProgresoReserva"]
    );

    const valorServicio = (precio: any) => {
      const dollarUSLocale = Intl.NumberFormat("es-CO");
      return "$ " + dollarUSLocale.format(precio);
    };

    const filtrarArrayPorProfesional = (profesional: any) => {
      //  detectarDevice()
      horariosDisponiblesFiltro.value =
        horariosDisponiblesOriginal.value.filter(
          (i: ServicioProfesionalResponse) =>
            i.profesional.id === profesional.id
        );
    };

    const lenHorariosDisponibles = computed(() => {
      if (disponibilidad.value.sucursalResponse !== undefined) {
        profesionalesMap.clear();

        horariosDisponiblesOriginal.value =
          disponibilidad.value.sucursalResponse[0].servicioProfesionalResponse;

        const respuesta: any =
          disponibilidad.value.sucursalResponse[0]
            .servicioProfesionalResponse[0];

        horariosDisponiblesOriginal.value.forEach(
          (e: ServicioProfesionalResponse) => {
            profesionalesMap.set(e.profesional.id + "", e.profesional);
          }
        );

        profesionalesMap.forEach((i) => {
          profesionales.value.push(i);
        });

        filtrarArrayPorProfesional(respuesta.profesional);

        return disponibilidad.value.sucursalResponse[0]
          .servicioProfesionalResponse.length;
      } else {
        return 0;
      }
    });

    const seleccionarReserva = async (disponibilidad: any) => {
      procesando.value = true;
      router.push({ name: "informacion-reserva", params: { id: id.value } });

      store.dispatch(
        "disponibilidad/seleccionarDisponibilidad",
        disponibilidad
      );

      const browser = Bowser.getParser(window.navigator.userAgent);
      const reserva: Reserva = new Reserva();
      reserva.fechaCreacion = new Date();
      reserva.fechaIngreso = consultandoDisponibilidadRequest.value.fecha;
      reserva.horaIngreso = disponibilidad.hora;
      reserva.idProfesional = disponibilidad.profesional.id;
      reserva.emailProfesional = disponibilidad.profesional.email;
      reserva.idServicio = disponibilidad.servicio.id;
      reserva.descripcionServicio = disponibilidad.servicio.nombre;
      reserva.idSucursal = disponibilidad.sucursal.id;
      reserva.direccionSucursal = disponibilidad.sucursal.direccion;
      reserva.ciudadSucursal = disponibilidad.sucursal.ciudad;
      reserva.emailSucursal = disponibilidad.sucursal.email;
      reserva.telefonoSucursal = disponibilidad.sucursal.telefono;
      reserva.nombreEstablecimiento =
        consultandoDisponibilidadRequest.value.establecimiento.nombre;
      reserva.idEstablecimiento =
        consultandoDisponibilidadRequest.value.establecimiento.id;
      reserva.nombreSucursal = disponibilidad.sucursal.nombre;
      reserva.direccionEstablecimiento =
        consultandoDisponibilidadRequest.value.sucursal.ciudad;
      reserva.nombreProfesional = `${disponibilidad.profesional.nombre} ${disponibilidad.profesional.apellido}`;
      reserva.estado = utils.statusReserva.BLOQUEADA;
      reserva.browser = browser.getBrowserName();
      reserva.browser_version = browser.getBrowserVersion();

      reserva.os = browser.getOSName();
      reserva.os_version = browser.getOSVersion();
      reserva.device = navigator.appName;
      reserva.deviceType = browser.getPlatform().type;
      reserva.userAgent = navigator.userAgent;

      switch (window.orientation) {
        case -90:
        case 90:
          reserva.orientation = "landscape";
          break;
        default:
          reserva.orientation = "portrait";
          break;
      }

      await fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          reserva.ip = ip;
          console.log(reserva);
        })
        .catch((error) => {});

      /*
      reserva.deviceType = this.deviceInfo.deviceType;
      reserva.orientation = this.deviceInfo.orientation;            
      reserva.sessionId = this.visita.sesionId;
      reserva.ip = this.ipInfo.ip;
      reserva.ipCiudad = this.ipInfo.city;
      reserva.ipPais = this.ipInfo.country;
      reserva.ipRegion = this.ipInfo.region;*/

      store.dispatch("reserva/guardarReserva", reserva);
      // router.push({ name: "informacion-reserva", params: { id: id.value } });
      procesando.value = false;
    };

    onMounted(() => {
      if (Number(progresoReserva.value) >= 3) {
        router.push({ name: "informacion-inicial", params: { id: id.value } });
      }

        
    });

    return {
      consultandoDisponibilidad,
      consultandoDisponibilidadRequest,
      disponibilidad,
      establecimiento,
      filtrarArrayPorProfesional,
      horariosDisponiblesFiltro,
      horariosDisponiblesOriginal,
      lenHorariosDisponibles,
      procesando,
      profesionales,
      progresoReserva,
      seleccionarReserva,
      valorServicio,
    };
  },
});
</script>

<style scoped>
.custom-card {
  margin: 0px 17px 17px 17px;
  background-color: #fff;
}
</style>