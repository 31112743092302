import { Genero } from './genero.model';
import { TipoIdentificacion } from './tipo-identificacion.model';

export class Reserva {

    public id?: string;
    public fechaIngreso?: Date;
    public horaIngreso?: string;
    public indicaciones?: string;
    public nombre?: string;
    public apellido?: string;
    public telefono?: string;
    public email?: string;
    public idplaneacion?: string;
    public codigoSeguridad?: string;
    public idHorario?: string;
    public idProfesional?: string;
    public idServicio?: string;
    public identificacion?: string;
    // public sucursal?: Sucursal;
    public idSucursal?: string;
    public tipoIdentificacion?: TipoIdentificacion;
    public genero?: Genero;
    public estado?: string;
    public fechaCreacion?: Date;
    public nombreEstablecimiento?: string;
    public nombreProfesional?: string;    
    public emailProfesional?: string;
    public direccionEstablecimiento?: string;
    public emailEstablecimiento?: string;
    public emailSucursal?: string;
    public telefonoSucursal?: string;
    public telefonoEstablecimiento?: string;
    public direccionSucursal?: string;
    public ciudadSucursal?: string;
    public nombreSucursal?: string;
    public urlCodigoQR?: string;
    public confirmada?: boolean;
    public fechaConfirmacion?: string;
    public tiempoServicioHoras?: string;
    public tiempoServicioMinutos?: string;
    public idEstablecimiento?: string;
    public browser?: string;
    public browser_version?: string;
    public device?: string;
    public deviceType?: string;
    public orientation?: string;
    public os?: string;
    public os_version?: string;
    public userAgent?: string;
    public sessionId?: string;
    public ip?: string;
    public ipCiudad?: string;
    public ipPais?: string;
    public ipRegion?: string;
    public color?: string;
    public descripcionServicio?: string;
}



