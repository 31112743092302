
import { Reserva } from "@/models/reserva.model";
import { ServicioProfesionalResponse } from "@/models/servicio-profesional-response.model";
import { Usuario } from "@/models/usuario.model";
import { Utils } from "@/utils/utils";
import { computed, defineComponent, onMounted, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import * as Bowser from "bowser";

export default defineComponent({
  name: "Disponibilidad",
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { id } = toRefs(props);

    const store = useStore();

    const router = useRouter();

    const utils: Utils = new Utils();

    const horariosDisponiblesOriginal = ref([]);
    const horariosDisponiblesFiltro = ref([]);
    const profesionales = ref(<Usuario[]>[]);
    const profesionalesMap: Map<string, Usuario> = new Map<string, Usuario>();
    const procesando = ref(false);

    const establecimiento = computed(
      () => store.getters["establecimiento/getterEstablecimiento"]
    );

    const consultandoDisponibilidadRequest = computed(
      () => store.getters["disponibilidad/getterDisponibilidadRequest"]
    );

    const consultandoDisponibilidad = computed(
      () => store.getters["disponibilidad/getterConsultandoDisponibilidad"]
    );

    const disponibilidad = computed(
      () => store.getters["disponibilidad/getterDisponibilidad"]
    );

    const progresoReserva = computed(
      () => store.getters["reserva/getterProgresoReserva"]
    );

    const valorServicio = (precio: any) => {
      const dollarUSLocale = Intl.NumberFormat("es-CO");
      return "$ " + dollarUSLocale.format(precio);
    };

    const filtrarArrayPorProfesional = (profesional: any) => {
      //  detectarDevice()
      horariosDisponiblesFiltro.value =
        horariosDisponiblesOriginal.value.filter(
          (i: ServicioProfesionalResponse) =>
            i.profesional.id === profesional.id
        );
    };

    const lenHorariosDisponibles = computed(() => {
      if (disponibilidad.value.sucursalResponse !== undefined) {
        profesionalesMap.clear();

        horariosDisponiblesOriginal.value =
          disponibilidad.value.sucursalResponse[0].servicioProfesionalResponse;

        const respuesta: any =
          disponibilidad.value.sucursalResponse[0]
            .servicioProfesionalResponse[0];

        horariosDisponiblesOriginal.value.forEach(
          (e: ServicioProfesionalResponse) => {
            profesionalesMap.set(e.profesional.id + "", e.profesional);
          }
        );

        profesionalesMap.forEach((i) => {
          profesionales.value.push(i);
        });

        filtrarArrayPorProfesional(respuesta.profesional);

        return disponibilidad.value.sucursalResponse[0]
          .servicioProfesionalResponse.length;
      } else {
        return 0;
      }
    });

    const seleccionarReserva = async (disponibilidad: any) => {
      procesando.value = true;
      router.push({ name: "informacion-reserva", params: { id: id.value } });

      store.dispatch(
        "disponibilidad/seleccionarDisponibilidad",
        disponibilidad
      );

      const browser = Bowser.getParser(window.navigator.userAgent);
      const reserva: Reserva = new Reserva();
      reserva.fechaCreacion = new Date();
      reserva.fechaIngreso = consultandoDisponibilidadRequest.value.fecha;
      reserva.horaIngreso = disponibilidad.hora;
      reserva.idProfesional = disponibilidad.profesional.id;
      reserva.emailProfesional = disponibilidad.profesional.email;
      reserva.idServicio = disponibilidad.servicio.id;
      reserva.descripcionServicio = disponibilidad.servicio.nombre;
      reserva.idSucursal = disponibilidad.sucursal.id;
      reserva.direccionSucursal = disponibilidad.sucursal.direccion;
      reserva.ciudadSucursal = disponibilidad.sucursal.ciudad;
      reserva.emailSucursal = disponibilidad.sucursal.email;
      reserva.telefonoSucursal = disponibilidad.sucursal.telefono;
      reserva.nombreEstablecimiento =
        consultandoDisponibilidadRequest.value.establecimiento.nombre;
      reserva.idEstablecimiento =
        consultandoDisponibilidadRequest.value.establecimiento.id;
      reserva.nombreSucursal = disponibilidad.sucursal.nombre;
      reserva.direccionEstablecimiento =
        consultandoDisponibilidadRequest.value.sucursal.ciudad;
      reserva.nombreProfesional = `${disponibilidad.profesional.nombre} ${disponibilidad.profesional.apellido}`;
      reserva.estado = utils.statusReserva.BLOQUEADA;
      reserva.browser = browser.getBrowserName();
      reserva.browser_version = browser.getBrowserVersion();

      reserva.os = browser.getOSName();
      reserva.os_version = browser.getOSVersion();
      reserva.device = navigator.appName;
      reserva.deviceType = browser.getPlatform().type;
      reserva.userAgent = navigator.userAgent;

      switch (window.orientation) {
        case -90:
        case 90:
          reserva.orientation = "landscape";
          break;
        default:
          reserva.orientation = "portrait";
          break;
      }

      await fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          reserva.ip = ip;
          console.log(reserva);
        })
        .catch((error) => {});

      /*
      reserva.deviceType = this.deviceInfo.deviceType;
      reserva.orientation = this.deviceInfo.orientation;            
      reserva.sessionId = this.visita.sesionId;
      reserva.ip = this.ipInfo.ip;
      reserva.ipCiudad = this.ipInfo.city;
      reserva.ipPais = this.ipInfo.country;
      reserva.ipRegion = this.ipInfo.region;*/

      store.dispatch("reserva/guardarReserva", reserva);
      // router.push({ name: "informacion-reserva", params: { id: id.value } });
      procesando.value = false;
    };

    onMounted(() => {
      if (Number(progresoReserva.value) >= 3) {
        router.push({ name: "informacion-inicial", params: { id: id.value } });
      }

        
    });

    return {
      consultandoDisponibilidad,
      consultandoDisponibilidadRequest,
      disponibilidad,
      establecimiento,
      filtrarArrayPorProfesional,
      horariosDisponiblesFiltro,
      horariosDisponiblesOriginal,
      lenHorariosDisponibles,
      procesando,
      profesionales,
      progresoReserva,
      seleccionarReserva,
      valorServicio,
    };
  },
});
